<template lang="">
  <div class="alert-mask active">
    <div
      class="popup-area mini active"
      style="width: 440px;"
      id="changePopup_password"
    >
      <div class="pop-header">
        <h1 class="pop-title">{{ $t('manager.pwUpdatePop.title') }}</h1>
        <button class="pop-x pop_close_btn" @click="$emit('close')">
          Close
        </button>
      </div>
      <div class="pop-contents">
        <div class="pop-password">
          <p v-html="$t('manager.pwUpdatePop.description')"></p>
          <ValidationObserver ref="form">
            <form id="updatePasswordForm">
              <p
                class="pad-b15"
                style="border-bottom: 1px solid #000; padding-top: 10px;"
              >
                <i class="ic-lock" style="vertical-align: bottom;"></i
                >{{ $t('manager.pwUpdatePop.oldPw') }}
                <text-input
                  rules="required"
                  label="Current Password"
                  type="password"
                  name="currentPwd"
                  v-model="currentPwd"
                  :errorData="currentPwdErr"
                  :inputStyle="currentPwdStyle"
                />
              </p>
              <p class="mar-t15">
                <i class="ic-lock" style="vertical-align: bottom;"></i
                >{{ $t('manager.pwUpdatePop.newPw') }}
                <text-input
                  :rules="{
                    required: true,
                    regex: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{9,30}$/,
                  }"
                  label="New Password"
                  type="password"
                  name="newPwd"
                  vid="newPwd"
                  v-model="newPwd"
                  :errorData="newPwdErr"
                  inputStyle="vertical-align: unset; margin-left: 62px;"
                />
              </p>
              <p class="mar-t15" style="display:inline-block">
                <i class="ic-lock" style="vertical-align: bottom;"></i
                >{{ $t('manager.pwUpdatePop.newPw-check') }}
                <text-input
                  rules="confirmed:newPwd"
                  label="Confirm New Password"
                  type="password"
                  name="checkNewPwd"
                  v-model="checkNewPwd"
                  :errorData="checkNewPwdErr"
                  :inputStyle="checkNewPwdStyle"
                />
              </p>
            </form>
          </ValidationObserver>
        </div>
        <div class="btns-bottom">
          <button
            type="button"
            class="bt-md bt-default pop_close_btn"
            @click="$emit('close')"
          >
            Cancel
          </button>
          <button type="button" class="bt-md bt-red" @click="passwordValidate">
            OK
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { updatePassword } from '@/api/serviceApi.js';
import TextInput from '@/components/common/TextInput';
import sha256 from 'sha256';
export default {
  components: { TextInput },
  data() {
    return {
      currentPwd: '',
      newPwd: '',
      checkNewPwd: '',
      currentPwdStyle: '',
      checkNewPwdStyle: '',
      currentPwdErr: {},
      newPwdErr: {},
      checkNewPwdErr: {},
    };
  },
  created() {
    this.initLocale();
  },
  methods: {
    // 한글/영문 버전에 따라 스타일 및 문구 변경
    initLocale() {
      if (this.$i18n.locale === 'en') {
        this.currentPwdStyle = 'vertical-align: unset; margin-left: 45px;';
        this.checkNewPwdStyle = 'vertical-align: unset; margin-left: 10px;';
        this.currentPwdErr = {
          style: 'margin-left: 205px; position: sticky; float: left;',
          message: this.$i18n.t('manager.pwUpdatePop.alert.oldPw-empty'),
        };
        this.newPwdErr = {
          style: 'margin-left: 205px; position: sticky; float: left;',
          message: this.$i18n.t('manager.pwUpdatePop.alert.newPw-empty'),
        };
        this.checkNewPwdErr = {
          style: 'margin-left: 205px; position: sticky; float: left;',
          message: this.$i18n.t('manager.pwUpdatePop.alert.newPw-not-match'),
        };
      } else {
        this.currentPwdStyle = 'vertical-align: unset; margin-left: 50px;';
        this.checkNewPwdStyle = 'vertical-align: unset; margin-left: 32px;';
        this.currentPwdErr = {
          style: 'margin-left: 190px; position: sticky; float: left;',
          message: this.$i18n.t('manager.pwUpdatePop.alert.oldPw-empty'),
        };
        this.newPwdErr = {
          style: 'margin-left: 190px; position: sticky; float: left;',
          message: this.$i18n.t('manager.pwUpdatePop.alert.newPw-empty'),
        };
        this.checkNewPwdErr = {
          style: 'margin-left: 190px; position: sticky; float: left;',
          message: this.$i18n.t('manager.pwUpdatePop.alert.newPw-not-match'),
        };
      }
    },
    // 유효성 검사
    passwordValidate() {
      this.$refs.form.validate().then(res => {
        if (!res) {
          this.$dialogs.alert('Check your inputs.');
          return;
        }
        this.updatePassword();
      });
    },
    // 비밀번호 수정
    async updatePassword() {
      let formData = {
        userId: sessionStorage.getItem('userId'),
        password: sha256(this.currentPwd),
        newPassword: sha256(this.newPwd),
      };
      const {
        data: { valid, message },
      } = await updatePassword(formData);
      if (valid) {
        this.$dialogs
          .alert('Successfully modified.')
          .then(() => this.$emit('close'));
      } else {
        this.$dialogs
          .alert(this.jsonEscape(message))
          .then(() => this.$emit('close'));
      }
    },
    jsonEscape(string) {
      var result = string.replace('\\n', '<br>');
      return result;
    },
  },
};
</script>
<style lang=""></style>
