<template>
  <div>
    <div class="container">
      <SubHeader :pathList="pathList" :title="title" />

      <!-- contents -->
      <div id="contents" class="contents">
        <div class="title-sort-box">
          <h3>{{ $t('manager.main-title1') }}</h3>
          <p class="red-txt" v-html="$t('common.required-input-guide')"></p>
        </div>
        <!--/title-sort-box-->
        <div class="table-horizon st01">
          <ValidationObserver ref="serviceForm">
            <form id="updateServiceForm" enctype="multipart/form-data">
              <table class="form-md">
                <colgroup>
                  <col style="width:10%" />
                  <col style="width:37%" />
                  <col style="width:16%" />
                  <col style="width:37%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label class="ic-star">{{
                        $t('manager.label-serviceId')
                      }}</label>
                    </th>
                    <td>
                      <text-input
                        :rules="{
                          required: true,
                          regex: /[0-9A-Za-z\-_]{4,20}/,
                        }"
                        label="Service ID"
                        name="srvcId"
                        maxlength="20"
                        v-model="srvcId"
                        disabled
                      />
                    </td>
                    <th>
                      <label class="ic-star">{{
                        $t('manager.label-serviceName')
                      }}</label>
                    </th>
                    <td>
                      <text-input
                        :rules="{
                          required: true,
                          regex: /[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|_-]{1,30}/,
                        }"
                        label="Service Name"
                        name="srvcNm"
                        maxlength="50"
                        v-model="srvcNm"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star">{{
                        $t('manager.label-redirectUri')
                      }}</label>
                    </th>
                    <td>
                      <text-input
                        :rules="{
                          required: true,
                          regex: /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                        }"
                        label="Redirect URI"
                        name="webServerRedirectUri"
                        maxlength="200"
                        v-model="webServerRedirectUri"
                        dataType="URL"
                      />
                    </td>
                    <th>
                      <label>{{ $t('manager.label-company') }}</label>
                    </th>
                    <td>
                      <text-input
                        label="Company Name"
                        name="cmpnyNm"
                        maxlength="25"
                        v-model="cmpnyNm"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star">{{
                        $t('manager.label-adminTel')
                      }}</label>
                    </th>
                    <td>
                      <text-input
                        :rules="{ required: true, regex: /^[0][0-9]{7,10}$/ }"
                        label="Admin Telephone"
                        name="chargerTelno"
                        maxlength="11"
                        v-model="chargerTelno"
                        dataType="NUMBER"
                      />
                    </td>
                    <th>
                      <label class="ic-star">{{
                        $t('manager.label-adminEmail')
                      }}</label>
                    </th>
                    <td>
                      <text-input
                        :rules="{
                          required: true,
                          regex: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
                        }"
                        label="Admin E-mail"
                        name="chargerEmail"
                        maxlength="50"
                        v-model="chargerEmail"
                        dataType="EMAIL"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label>{{ $t('manager.label-contents') }}</label>
                    </th>
                    <td colspan="3">
                      <text-input
                        label="Description"
                        name="dc"
                        maxlength="200"
                        v-model="dc"
                        type="textarea"
                        inputStyle="height: 150px;"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </ValidationObserver>
        </div>
        <div class="btns-bottom" style="text-align: right;">
          <button type="button" class="bt-md bt-red" @click="serviceValidate">
            <span>{{ $t('common.btn-submit') }}</span>
          </button>
        </div>
        <div class="title-sort-box">
          <h3>{{ $t('manager.main-title2') }}</h3>
          <p class="red-txt" v-html="$t('common.required-input-guide')"></p>
        </div>
        <!--/title-sort-box-->
        <div class="table-horizon st01">
          <ValidationObserver ref="operatorForm">
            <form enctype="multipart/form-data">
              <table class="form-md">
                <colgroup>
                  <col style="width:10%" />
                  <col style="width:37%" />
                  <col style="width:16%" />
                  <col style="width:37%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label class="ic-star">{{
                        $t('manager.label-adminId')
                      }}</label>
                    </th>
                    <td>
                      <text-input
                        :rules="{
                          required: true,
                          regex: /[0-9A-Za-z\-_]{4,20}/,
                        }"
                        label="Service Admin ID"
                        name="userId"
                        maxlength="20"
                        disabled
                        v-model="userId"
                      />
                    </td>
                    <th>
                      <label class="ic-star">{{
                        $t('manager.label-adminName')
                      }}</label>
                    </th>
                    <td>
                      <text-input
                        :rules="{
                          required: true,
                          regex: /[ㄱ-ㅎ|가-힣|a-z|A-Z|0-9|_-]{1,30}/,
                        }"
                        label="Admin Name"
                        name="userName"
                        maxlength="20"
                        v-model="userName"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star">{{
                        $t('manager.label-adminTel2')
                      }}</label>
                    </th>
                    <td>
                      <text-input
                        :rules="{
                          required: true,
                          regex: /^[0][0-9]{7,10}$/,
                        }"
                        label="Admin Telephone"
                        name="mobileNo"
                        maxlength="11"
                        v-model="mobileNo"
                        dataType="NUMBER"
                      />
                    </td>
                    <th>
                      <label class="ic-star">{{
                        $t('manager.label-adminEmail2')
                      }}</label>
                    </th>
                    <td>
                      <text-input
                        :rules="{
                          required: true,
                          regex: /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
                        }"
                        label="Admin E-mail"
                        name="email"
                        maxlength="50"
                        v-model="email"
                        dataType="EMAIL"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label class="ic-star">{{
                        $t('manager.label-adminPw')
                      }}</label>
                    </th>
                    <td>
                      <button
                        type="button"
                        class="bt-md bt-default"
                        @click="pwdUpdatePopup = true"
                      >
                        <span>{{ $t('manager.btn-change-pw') }}</span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </form>
          </ValidationObserver>
        </div>
        <div class="btns-bottom" style="text-align: right;">
          <button type="button" class="bt-md bt-red" @click="operatorValidate">
            <span>{{ $t('common.btn-submit') }}</span>
          </button>
        </div>
      </div>
      <!--/contents-->
    </div>
    <!--/container-->
    <password-check-popup
      v-if="pwdCheckPopup"
      @close="pwdCheckPopup = false"
      :fetchApi="checkPassword"
      @next="updateManager"
    />
    <update-password-popup
      v-if="pwdUpdatePopup"
      @close="pwdUpdatePopup = false"
    />
  </div>
</template>
<script>
import TextInput from '@/components/common/TextInput.vue';
import {
  fetchServceDetail,
  fetchOperatorDetail,
  checkPassword,
  updateService,
  updateOperator,
} from '@/api/serviceApi.js';
import PasswordCheckPopup from '@/components/common/PasswordCheckPopup';
import UpdatePasswordPopup from './UpdatePasswordPopup.vue';
export default {
  components: { TextInput, PasswordCheckPopup, UpdatePasswordPopup },
  data() {
    return {
      pathList: [
        this.$i18n.t('menu.home'),
        this.$i18n.t('menu.serviceAdmin'),
        this.$i18n.t('subMenu.manager'),
      ],
      title: {
        mainTitle: this.$i18n.t('menu.serviceAdmin'),
        smallTitle: this.$i18n.t('menu.serviceAdmin-eng'),
      },
      srvcId: '',
      srvcNm: '',
      webServerRedirectUri: '',
      cmpnyNm: '',
      chargerTelno: '',
      chargerEmail: '',
      dc: '',
      userId: '',
      userName: '',
      mobileNo: '',
      email: '',
      pwdCheckPopup: false,
      category: '',
      pwdUpdatePopup: false,
    };
  },
  created() {
    this.fetchServceDetail();
    this.fetchOperatorDetail();
  },
  methods: {
    // 서비스 정보 데이터
    async fetchServceDetail() {
      const { data } = await fetchServceDetail();
      Object.assign(this.$data, data);
    },
    // 서비스 관리자 정보 데이터
    async fetchOperatorDetail() {
      const { data } = await fetchOperatorDetail();
      Object.assign(this.$data, data);
    },
    // 서비스 정보 유효성 검사
    serviceValidate() {
      this.$refs.serviceForm.validate().then(res => {
        if (!res) {
          this.$dialogs.alert('Please check you inputs.');
          return;
        }
        this.category = 'service';
        this.pwdCheckPopup = true;
      });
    },
    // 서비스 관리자 정보 유효성 검사
    operatorValidate() {
      this.$refs.operatorForm.validate().then(res => {
        if (!res) {
          this.$dialogs.alert('Please check you inputs.');
          return;
        }
        this.category = 'operator';
        this.pwdCheckPopup = true;
      });
    },

    // 비밀번호 확인
    checkPassword(params) {
      return checkPassword(params);
    },
    // 비밀번호 체크후 해당 정보 수정
    updateManager() {
      this.pwdCheckPopup = false;
      if (this.category === 'service') {
        this.updateService();
      } else {
        this.updateOperator();
      }
    },
    // 서비스 정보 수정
    async updateService() {
      let formData = {
        srvcId: this.srvcId,
        srvcNm: this.srvcNm,
        cmpnyNm: this.cmpnyNm,
        webServerRedirectUri: this.webServerRedirectUri,
        chargerTelno: this.chargerTelno,
        chargerEmail: this.chargerEmail,
        dc: this.dc,
      };
      const {
        data: { valid },
      } = await updateService(formData);
      if (valid) {
        this.$dialogs
          .alert('Successfully modified.')
          .then(() => this.$router.go());
      }
    },
    // 서비스 관리자 정보 수정
    async updateOperator() {
      let formData = {
        userId: this.userId,
        userName: this.userName,
        mobileNo: this.mobileNo,
        email: this.email,
      };
      const {
        data: { valid },
      } = await updateOperator(formData);

      if (valid) {
        this.$dialogs
          .alert('Successfully modified.')
          .then(() => this.$router.go());
      }
    },
  },
};
</script>
<style></style>
